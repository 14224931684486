import React from 'react';
import css from './SideShoppingCartOverlay.module.css';
import ShoppingCartSlideIn from './ShoppingCartSlideIn';
import NamedLink from '../NamedLink/NamedLink';
import recent1 from '../../assets/recent1.jpg';
import { useState } from 'react';
import Button from '../Button/Button';
import classNames from 'classnames';
import { LISTING_STATE_CLOSED } from '../../util/types';


const SideShoppingCartOverlayComponent = props =>{
    const activeClassName = 'my-active-class';
    const {showForm,
        handleHideShoppingCart, 
        submitLogin,
        inProgress,
        cartData,
        cartDataDetails,
        handleRemoveItem,
        currentUser
    } = props;

    let stylee = showForm?css.sideFormShow : css.sideForm;

    
//   const commonParams = { params, history, routes: routeConfiguration };
//   const onSubmit = handleSubmit({
//     ...commonParams,
//     currentUser,
//     callSetInitialValues,
//     getListing,
//     onInitializeCardPaymentData,
    
//   });
    
//   const handleOrderSubmit = values => {

//     const currentListing = cartDataDetails[0];
//     const authorAvailable = currentListing && currentListing.author;
//     const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
//     const isOwnListing =
//       userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

//     console.log("ooooooouuuuuuuuuuuooo--------------------");
//     const isCurrentlyClosed = cartDataDetails[0].attributes.state === LISTING_STATE_CLOSED;
//     console.log("ooooooouuuuppppppppppppppppppppuuuuuuuooo");
//     if (isOwnListing || isCurrentlyClosed) {
//       window.scrollTo(0, 0);
//     } else {
//       values={"quantity":"1",
//         listing:currentListing,
//       };
//       console.log("oooooooooo");
//       console.log(values);

//       localStorage.setItem("CurrentAction", "Cart Payment");
//       console.log(localStorage.getItem("CurrentAction"));
//       onSubmit(values);
//     }
//   };

    const view = <>
                    <div className={'py-1 ' + stylee}>
                        <div className={css.headerRow}>
                            <h3>Shopping cart</h3>
                            <button onClick={handleHideShoppingCart} className={css.closeBtn}>
                                <svg className={css.closeIcon} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                    <path className={css.closeIcon} d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"></path></svg>
                                Close
                            </button>
                            
                        </div>
                       <hr/>

                       <ShoppingCartSlideIn 
                            hideShoppingCart={true} 
                            className={css.loginForm} 
                            onSubmit={submitLogin} 
                            inProgress={inProgress} 
                            cartData={cartData}
                            handleRemoveItem={handleRemoveItem}
                       />

                       <footer>
                            <hr/>
                            <div className={css.footerLinkCon}>
                                <NamedLink name="CartPage">VIEW CART</NamedLink>
                                
                            </div>
                            
                       </footer>
                        
                    </div>

                    {showForm?<div id="sideFormOverlay" onClick={handleHideShoppingCart} className={css.sideFormOverlay}></div>:""}
                </>;

  return (
    
    <>
        {view}
    </>
    
    
  );
};

////
export default SideShoppingCartOverlayComponent;
