import React from 'react';
import css from './CartSmall.module.css';
import ShoppingCartSlideIn from './ShoppingCartSlideIn';
import NamedLink from '../NamedLink/NamedLink';
import recent1 from '../../assets/recent1.jpg';


const CartComponentSmall = props =>{

    const {cartData,handleRemoveItem} = props;
    
  return (
    
    <>
            { cartData !== undefined? cartData.map((data,key)=>{ 
                
                return(
                    <div className={css.noSpacing} key={key}>
                        <div className={css.flexRow}>
                            <div className='d-flex gap-3 justify-content-start align-items-center'>
                                <img src={data.img} className={css.resize}/>
                                <div className={css.detailsCon}>
                                    <h4 className={css.shortText}>{data.title}</h4>
                                    
                                    <p className={css.quantity}>{data.quantity} x ${data.price}</p>
                                </div>
                            </div>
                            
                            <button onClick={event => handleRemoveItem(event, data.id)} className={css.noBorder}>
                                <svg   xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                    <path className={css.closeBtn} onClick={event => handleRemoveItem(event, data.id)} d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                                </svg>
                            </button>
                            
                        </div>
                        <hr className={css.rul}/>
                    </div>
                )
            }):""}
    </>
    
    
  );
};

////
export default CartComponentSmall;
