import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';

import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { injectIntl } from 'react-intl';
import { intlShape } from '../../util/reactIntl';
import CartComponent from './Cart';
import CartComponentSmall from './CartSmall';


const ShoppingCartSlideInComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {


      const location = useLocation();
      
      const path = location.pathname;
      //const url = window.location.pathname.split('/').pop();

      const {
        
        cartData,
        handleRemoveItem,
      } = fieldRenderProps;

      const sideForm = useRef(null);
      useEffect(
        ()=>{
  
          if(path==="/side-menu" && sideForm.current != null){
            console.log("Animate side form ----------------------------");
           
          }

            },[]
      );
      
//
     
      return (
        <div ref={sideForm}>
         <CartComponentSmall cartData={cartData} handleRemoveItem={handleRemoveItem}/>
        </div>
       
      );
    }}
  />
);

ShoppingCartSlideInComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

ShoppingCartSlideInComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const ShoppingCartSlideIn = compose(injectIntl)(ShoppingCartSlideInComponent);
ShoppingCartSlideIn.displayName = 'ShoppingCartSlideInComponent';

export default ShoppingCartSlideIn;
