import React, { useEffect, useRef, useState } from 'react';
import css from './SimpleCard.module.css';
import classNames from 'classnames';

const SimpleCardComponent = props =>{
    const {title,productCount,img,onClick} = props;

    const elementRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
  
    useEffect(() => {
      const handleScroll = () => {
        if (elementRef.current) {
          const rect = elementRef.current.getBoundingClientRect();
          const isVisible = (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
          );
          setIsVisible(isVisible);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      // Initial check on component mount
      handleScroll();
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

  
  return (
    
    <>
            <div onClick={onClick} ref={elementRef} className={css.cardCon}>
                <div>   
                    <div className={classNames(css.img_con,css.cardCon)}>
                        <img className={css.resize} src={img} />
                    </div>
                    <div className={css.overlay_caption}>
                        <h3>{title}</h3>
                        
                        <p className={css.quantity}>{productCount} products</p>
                        
                    </div>
                </div>

            </div>
       
        
    </>
    
  );
};


export default SimpleCardComponent;
