import React, { useEffect, useRef, useState } from 'react';
import css from './Home.module.css';

import classNames from 'classnames';
import CustomFooterComponent from './CustomFooter';
import MenuIcon from '../../containers/TopbarContainer/Topbar/MenuIcon';
import Button from '../Button/Button';
import f1 from '../../assets/banner1.png';
import f2 from '../../assets/banner2.png';
import f3 from '../../assets/banner3.png';
import f4 from '../../assets/banner4.png';
import woman from '../../assets/1.png';
import girls from '../../assets/2.png';
import acces from '../../assets/3.png';
import boys from '../../assets/4.png';
import digital from '../../assets/5.png';

import brand1 from '../../assets/brand1.png';
import brand2 from '../../assets/brand2.png';
import brand3 from '../../assets/brand3.png';
import brand4 from '../../assets/brand4.jpeg';
import logo from '../../assets/over2.png';
import buysell from '../../assets/over3.png';
import buysell2 from '../../assets/over4.png';
import { getFormattedTotalPrice } from '../../containers/CheckoutPage/CheckoutPageTransactionHelpers';
import RecentlyAddedComponent from './RecentlyAdded';

import SocialComponent from './SocialContent';
import SideFormComponent from './SideForm';
import SimpleCardComponent from './SimpleCard';
import SmartComponent from './SmartCom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import SideShoppingCartOverlayComponent from './SideShoppingCartOverlay';


const HomeComponent1 = props =>{
    const {entities,
      submitLogin,
      inProgress,
      createCart,
      cartData,
      cartDataDetails,
      removeCartItem,
      addToWishlist,
      wishlistId,
      createWishlistInProgress,
      createWishlistError,
      getAuthor,
      currentUser
    } = props;
    const activeClassName = 'my-active-class';
    const {showSideForm,showShoppingCartForm,handleHideForm,handleHideShoppingCart} = props;
    
  //const [cartData , setCartData] = useState([]);
   
    const checkIfExist = (obj) => {
   
        if(obj === undefined || obj === null)return[];
        const keys = Object?.keys(obj);
        keys.forEach(key => {
          try{
              if(parseInt(obj[0]) !== undefined && obj[key].refId === refId){
                console.log(obj[key].refId+"   ---------------------------------------     "+refId);
                listExist = true;
              }
          }catch(error){}
        });
       ;
      };

      const separateObject = obj => {
        if(listExist)return[];
       
        if(obj === undefined || obj === null)return[];
        const res = [];
        const keys = Object?.keys(obj);
        keys.forEach(key => {
          
          try{
             
              res.push(
                obj[key]
              );
    
          }catch(error){}
         
        });
        return res;
      };

      const getCount = (obj,searchText) => {
        if(obj === undefined || obj === null || obj.listing === undefined || obj.listing === null)return[];
        let count = 0;
        const keys = Object?.keys(obj.listing);
        keys.forEach(key => {
          try{
              let categories = obj.listing[key].attributes.publicData.category;
              const isFound = categories.includes(searchText);

              if(parseInt(obj[0]) !== undefined && isFound){
               count += 1;
              }
          }catch(error){}
        });
        return count;
      };
     
      const history = useHistory();

      const womenCount = getCount(entities,"women");
      const girlsCount = getCount(entities,"girls");
      const boysCount = getCount(entities,"boys_and_men");
      const digitalCount = getCount(entities,"digital_goods");
      const accessoryCount = getCount(entities,"accessories");

      const handleAddToCart = (event,listingId,title,description,price,quantity,img,stockVal,stockCount,authorId,storeName)=>{

        //Check if exist
        const exist = cartData !== undefined && cartData.filter(item=> item.id === listingId);
        if(exist && exist.length !== 0){
          handleHideShoppingCart();
          return;
        };
        
        const listingItem = {
                              id:listingId,
                              img:img,
                              title:title,
                              desc:description,
                              price:price,
                              quantity:quantity,
                              currentStock:stockCount,
                              authorId:authorId,
                              storeName:storeName
                            };
        
        const newData = [listingItem]
        
        //setCartData(newData);
        if(stockVal !== "Not in stock"){
          createCart(newData);
        }
        
        handleHideShoppingCart();
      }

      const handleAddToWishlist = (event,listingId,title,description,price,quantity,img)=>{
        const listingItem = {
                              id:listingId,
                              img:img,
                              title:title,
                              desc:description,
                              price:price,
                              quantity:quantity
                            };
        
        const newData = [listingItem]
        
        //setCartData(newData);
        addToWishlist(newData);
        
      }
      

      const handleRemoveItem = (event,id)=>{
        removeCartItem(id);
    }

      const handleShopClick = (event)=>{
        console.log("clicke level 1 -----------");
        history.push("/s");
      }
    
      const handleWomanClick = (event)=>{
        console.log("clicke level 1 -----------");
        history.push("/s?pub_category=women")
      }
    
      const handleGirlClick = (event)=>{
        console.log("clicke level 1 -----------");
        history.push("/s?pub_category=girls");
      }
    
      const handleBoyClick = (event)=>{
        console.log("clicke level 1 -----------");
        history.push("/s?pub_category=boys_and_men");
      }
    
      const handleDigitalClick = (event)=>{
        console.log("clicke level 1 -----------");
        history.push("/s?pub_category=digital_goods");
      }
    
      const handleAccessClick = (event)=>{
        console.log("clicke level 1 -----------");
        history.push("/s?pub_category=accessories");
      }
    
      



    const {blocks} = props;

    const view = 
                    <>
                        <div className={classNames(css.container,css.desktop)}>
                           
                           
                        </div>

                        <div className={css.hide}>
                            <div className={css.sub_menu}>
                                <div><span className={css.weight_normal}>Home/Shop</span></div>
                                <div> <span className={css.weight_normal}>Showing 1–12 of 216 results</span></div>
                            </div>

                            <div className={css.sub_menu}>
                                <div>
                                    
                                    <div className={css.menuCon}>
                                        <Button
                                        rootClassName={css.menu}
                                        
                                        title={""}
                                        >
                                            <MenuIcon className={css.menuIcon} />
                                        </Button>
                                        MENU
                                    </div>

                                </div>
                                <div> <span className={css.weight_normal}>Showing 1-12 of 216 results</span></div>
                            </div>
                        </div>

                       <div className={css.slide_con}>
                            <center>
                                    <div id="carouselExampleRide" class="carousel slide bg-secondary" data-bs-ride="carousel">
                                        <div class="carousel-inner">


                                          {blocks.map((block,key)=>{
                                            const curImg = block.media.image.attributes.variants["original2400"].url;
                                            return(
                                              <div class="carousel-item active">
                                                  <img className={css.slideSize} src={curImg} alt=""/>
                                                  <div  className={css.bannerOverlay}>
                                                      <img className={css.resizeBannerImg0} src={logo} />
                                                  </div>
                                                  <div  className={css.bannerOverlay2}>
                                                      <img className={css.resizeBannerImg} src={buysell} />
                                                  </div>
                                                  <div  className={css.bannerOverlay3}>
                                                      <img className={css.resizeBannerImg2} src={buysell2} />
                                                  </div>
                                              </div>
                                            )

                                          })}
                                        </div>
                                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleRide" data-bs-slide="prev">
                                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span class="visually-hidden">Previous</span>
                                        </button>
                                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleRide" data-bs-slide="next">
                                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span class="visually-hidden">Next</span>
                                        </button>
                                    </div>
                            </center>

                            <div className={css.categories}>
                                <SimpleCardComponent onClick={handleAccessClick} title={"ACCESSORIES"} productCount={accessoryCount} img={acces} />
                                <SimpleCardComponent onClick={handleBoyClick} title={"BOYS & MEN"} productCount={boysCount} img={boys} />
                                <SimpleCardComponent onClick={handleDigitalClick} title={"DIGITAL GOODS"} productCount={digitalCount} img={digital} />
                                <SimpleCardComponent onClick={handleGirlClick} title={"GIRLS"} productCount={girlsCount} img={girls} />
                                <SimpleCardComponent onClick={handleWomanClick} title={"WOMEN"} productCount={womenCount} img={woman} />
                            </div>

                       </div>

                       <RecentlyAddedComponent 
                          entities={entities} 
                          handleAddToCart={handleAddToCart} 
                          handleAddToWishlist={handleAddToWishlist} 
                          wishlistId={wishlistId}
                          createWishlistInProgress={createWishlistInProgress}
                          createWishlistError={createWishlistError}
                       />

                       <center class={css.mainBorder} >
                                    <div id="carouselExampleRide2"  class="carousel slide bg-white" data-bs-ride="carousel">
                                        <div class="carousel-inner mainBorder">
                                            <div class="carousel-item active ">
                                                <img className={css.imgSmall} src={brand1} alt="" width="auto" height="150px"/>
                                                <img className={css.imgSmall} src={brand2} alt="" width="auto" height="150px"/>
                                                <img className={css.imgSmall} src={brand3} alt="" width="auto" height="150px"/>
                                                <img className={css.imgSmall} src={brand4} alt="" width="auto" height="150px"/>
                                                <img className={css.imgSmall} src={brand1} alt="" width="auto" height="150px"/>
                                                <img className={css.imgSmall} src={brand2} alt="" width="auto" height="150px"/>
                                                
                                            </div>
                                            <div class="carousel-item">
                                                <img className={css.imgSmall} src={brand1} alt="" width="auto" height="150px"/>
                                                <img className={css.imgSmall} src={brand2} alt="" width="auto" height="150px"/>
                                                <img className={css.imgSmall} src={brand3} alt="" width="auto" height="150px"/>
                                                <img className={css.imgSmall} src={brand4} alt="" width="auto" height="150px"/>
                                                <img className={css.imgSmall} src={brand1} alt="" width="auto" height="150px"/>
                                                <img className={css.imgSmall} src={brand2} alt="" width="auto" height="150px"/>
                                                
                                            </div>
                                            <div class="carousel-item">
                                                <img className={css.imgSmall} src={brand1} alt="" width="auto" height="150px"/>
                                                <img className={css.imgSmall} src={brand2} alt="" width="auto" height="150px"/>
                                                <img className={css.imgSmall} src={brand3} alt="" width="auto" height="150px"/>
                                                <img className={css.imgSmall} src={brand4} alt="" width="auto" height="150px"/>
                                                <img className={css.imgSmall} src={brand1} alt="" width="auto" height="150px"/>
                                                <img className={css.imgSmall} src={brand2} alt="" width="auto" height="150px"/>
                                                
                                            </div>
                                           
                                        </div>
                                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleRide2" data-bs-slide="prev">
                                            <span class="carousel-control-prev-icon color_dark" aria-hidden="true"></span>
                                            <span class="visually-hidden">Previous</span>
                                        </button>
                                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleRide2" data-bs-slide="next">
                                            <span class="carousel-control-next-icon color_dark" aria-hidden="true"></span>
                                            <span class="visually-hidden">
                                                Next
                                            </span>
                                        </button>
                                    </div>
                            </center>

                            <SideFormComponent showForm={showSideForm} handleHideForm={handleHideForm} submitLogin={submitLogin} inProgress={inProgress}/>
                            <SideShoppingCartOverlayComponent
                              showForm={showShoppingCartForm}
                              handleHideShoppingCart={handleHideShoppingCart}
                              submitLogin={submitLogin}
                              inProgress={inProgress}
                              cartData={cartData}
                              handleRemoveItem={handleRemoveItem}
                              cartDataDetails={cartDataDetails}
                              currentUser={currentUser}
                            />
                    </>
                    ;

  return (
    
    <>
        {view}
        
    </>
    
  );
};



const Timer = ()=> {
    const startNow = React.useMemo(() => Date.now(), []);
    const [now, setNow] = React.useState(startNow);
  
    const secondsPassed = ~~((now - startNow) / 1000);
  
    const startTime = 60 * 6;
    const timeLeft = startTime - (secondsPassed % startTime);
  
    const minuteCount = ~~(timeLeft / 60);
    const secondsCount = timeLeft % 60;
    const countdown = `${minuteCount}:${secondsCount.toLocaleString("en-US", {
      minimumIntegerDigits: 2
    })}`;
  
    useEffect(() => {
      const timer = setInterval(() => setNow(Date.now()), 1000);
      return () => clearInterval(timer);
    }, []);
  
    return (<div > {countdown}</div>) ;
  };


export default HomeComponent1;
